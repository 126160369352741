import Spinner from "../assets/tube-spinner.svg";
import { motion } from "framer-motion";
import { useState, useEffect } from 'react';
import { useStoreState } from "../store";

export default function Loader() {
  const { settings } = useStoreState();
  const loaderFactsTextColor = settings.loaderFactsTextColor ?? "text-black";

  const facts = [
    'Ditching The Razor Could Save You 2,600 Gallons Of Water Per Year!',
    'Laser Hair Removal Could Save You $6,000+ Over A Lifetime',
    'Shavers Toss 12 Razors On Average Per Year',
  ];

  const [currentFact, setCurrentFact] = useState(facts[0]);

  useEffect(() => {
    /* One fact each 5 secs */
    let interval = setInterval(() => {
      const randomFact = facts[Math.floor(Math.random() * facts.length)];
      setCurrentFact(randomFact);
    }, 5000);
    return () => clearInterval(interval);  //clean up function
  }, []);

  return (
    <>
      <div className="loader w-32 text-center mx-auto">
        <img src={Spinner} alt="Loading" />
      </div>
      {/* Facts */}
      <motion.div
        className="facts-loader w-72 text-center mx-auto mt-8"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <span className={`font-bold text-4xl ${loaderFactsTextColor}`}>Did you know?</span>
        <motion.p
          key={currentFact}
          className={`fact mt-4 text-base font-normal text-center ${loaderFactsTextColor}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          role="status"
          aria-live="polite"
        >
          {currentFact}
        </motion.p>
      </motion.div>
    </>
  );
}
