import FormStep from "../FormStep";
import Accolades from "../Accolades";
import { NavigationButton } from "../NavigationButton";
import { useStoreActions, useStoreSelectors, useStoreState } from "../../store";
import LegalConsent from "../LegalConsent";
import PropTypes from "prop-types";
import { getThemeStyles, getThemeClassString } from "../../themes";
import { useRef, useEffect } from "react";

export default function StandardForm() {
  const { settings, flow } = useStoreState();
  const { formEmphasis, showAccolades, showStepsIndicator, buttonMode, theme } = settings;
  const { selectHasPreviousStep, selectCurrentStep, selectCanAdvance, selectNextButtonText } = useStoreSelectors();
  const { navigateBack, navigateForward } = useStoreActions();
  const currentStep = selectCurrentStep();
  const hasPreviousStep = selectHasPreviousStep();
  const canAdvance = selectCanAdvance();
  const nextButtonText = selectNextButtonText();
  const showLegalConsent = currentStep.lastStep && settings.showLegalConsentText;
  const themeClasses = getThemeStyles(theme);

  const nextButtonBackgroundClass = settings.nextButtonBackgroundClass ?? "bg-pink-electric";

  const formBodyClasses = formEmphasis ? "w-full h-full rounded-b-6xl border-4 border-zinc-200 border-solid border-t-0 bg-grey-light pt-8 pb-6 standard-form__inputs-wrapper" : "standard-form__inputs-wrapper";
  const headerClasses = formEmphasis
    ? "flex flex-col rounded-t-6xl rounded-tr-6xl sm:flex-row-reverse bg-grey-dark p-8 justify-between w-full text-white standard-form__title-wrapper"
    : "text-grey-dark mb-4 standard-form__title-wrapper";

  const headerMargins = formEmphasis ? "" : "mb-4";
  const formPadding = formEmphasis ? "form-emphasis px-5 lg:px-4" : "form-non-emphasis px-2";
  const showBackButton = settings.backButton && hasPreviousStep;

  const questionElementRef = useRef(null);
  useEffect(() => {
    const questionElement = questionElementRef.current;
    if (questionElement == null) {
      return;
    }
    if (currentStep.firstStep) {
      return;
    }
    if (currentStep.answers?.some((answer) => answer.selected)) {
      return;
    }
    questionElement.focus();
  }, [currentStep]);

  return (
    <>
      <div className={`${getThemeClassString(themeClasses, ["headerBackground", "headerTextColor", "topBorderRadius", "textCenter", "padding"], headerClasses )}`}>
        {showStepsIndicator && <div className={`font-medium ${headerMargins} text-xs uppercase standard-form__step`}>STEP {flow.length}</div>}
        {currentStep && (
          <div data-testid="form-question" className={`font-bold ${headerMargins} standard-form__question text-xl ${currentStep.questionClass} `} tabIndex={0} ref={questionElementRef}>
            {currentStep.question}
          </div>
        )}
      </div>
      <div className={`${formBodyClasses}`}>
        <div className={`${formPadding} ${getThemeClassString(themeClasses, ["backgroundColor", "paddingY", "paddingX", "bottomBorderRadius"])} w-full mx-auto`}>
          <FormStep />
          <div className="w-full mt-4 flex flex-col justify-between">
            {showLegalConsent && <LegalConsent />}
            <div className="standard-form__navigation-wrapper flex flex-row gap-4">
              {showBackButton && <NavigationButton text="Previous" backgroundClasses={"bg-gray-400 standard-form__prev-button"} onNavButtonClicked={() => navigateBack()} tabIndex={0} />}

              <NavigationButton
                text={nextButtonText}
                buttonMode={buttonMode ?? "round"}
                backgroundClasses={`${nextButtonBackgroundClass} standard-form__next-button`}
                onNavButtonClicked={() => navigateForward()}
                disabled={!canAdvance}
              />
            </div>
          </div>
        </div>
        {showAccolades && <Accolades />}
      </div>
    </>
  );
}

StandardForm.propTypes = {
  questionRef: PropTypes.node,
};
