import { useStoreSelectors, useStoreState, useStoreActions } from "../store";
import { useState } from 'react';
import ArrowRight from "../assets/arrow-right.svg";

export function NearestLocationsSelect() {
  const { selectIsExitModal } = useStoreSelectors();
  const { settings, nearestLocations } = useStoreState();
  const { changeAnswer } = useStoreActions();

  const locationsPerPage = settings.locationsPerPage;
  const maxLocations = settings.maxLocations;

  const selectedLocation = nearestLocations.find((location) => location.selected);
  const initialPage = Math.floor(selectedLocation?.index / locationsPerPage) + 1;
  const [locationsToShow, setLocationsToShow] = useState(initialPage * locationsPerPage);

  const handleInputChange = (event) => {
    changeAnswer(event.target.value);
  };

  const seeMore = () => {
    setLocationsToShow(locationsToShow + locationsPerPage);
  };

  const getFormatedTitle = (title) => {
    return title.replace("&#8211;", '-');
  };

  const errorClasses = settings.inputErrorClass ?? (selectIsExitModal() ? "text-white" : "text-red-500");
  const isReady = nearestLocations && nearestLocations.length > 0;

  const statusClasses = {
    location: {
      visible: "bg-grey-soft border-grey-mist",
      active: "bg-[#F5E4E7] border-pink-electric shadow[0_4px_4px_0_rgba(0,0,0,0.25)] focus-within:outline focus-within:outline-dashed focus-within:outline-black focus-within:outline-2 focus-within:outline-offset-2",
    },
    badge: {
      visible: "bg-[#7C7C7C]",
      active: "bg-pink-electric",
    }
  };

  return (
    <div className="nearest-locations w-full">
      {isReady && (
        <>
          <div className="nearest-locations__list max-h-[460px] p-2 overflow-y-auto">
            {nearestLocations.map((location, index) => {
              const locationStatus = location.selected ? 'active' : 'visible';
              const distance = `${location.distance.toFixed(1)} mi`;

              return index < locationsToShow && (
                <label
                  key={location.post_id}
                  className={`nearest-locations__list-item flex flex-col w-full box-border px-5 py-[14px] border border-solid rounded-[10px] mb-4 focus-within: cursor-pointer ${statusClasses['location'][locationStatus]}`}
                >
                  <input type="radio" name={location.input_name} className="w-0 h-0" value={location.wpsl_form_dropdown_value} onChange={handleInputChange} />
                  <span className="nearest-locations__list-item-heading flex justify-between mb-1 font-bold text-[#000000]">
                    <span>{getFormatedTitle(location.post_title)}</span>
                    <span className={`nearest-locations__list-item-badge shrink-0 h-[22px] flex items-center rounded-xl px-4 text-sm text-white ${statusClasses['badge'][locationStatus]}`}>{distance}</span>
                  </span>
                  <span>{location.wpsl_address}</span>
                  {location.wpsl_address2 && <span>{location.wpsl_address2}</span>}
                  <span>{location.wpsl_city}, {location.wpsl_state} {location.wpsl_zip}</span>
                </label>
              );
            })}
          </div>

          {locationsToShow < maxLocations && (
            <div className="nearest-locations__show-more">
              <button onClick={seeMore} className="flex items-center w-full justify-center md:justify-start gap-2 px-5 py-3 bg-transparent border-none cursor-pointer">
                <span className="text-pink-electric font-bold text-lg">See More </span>
                <span className="bg-pink-electric rounded-full w-5 h-5 flex items-center justify-center"><img src={ArrowRight} alt="See More" /></span>
              </button>
            </div>
          )}
        </>
      )}
      {!isReady && (
        <div className={`mt-2 text-sm ${errorClasses}`}>
          We couldn't find any location near your zipcode, please make sure you enter a valid value.
        </div>
      )}
    </div>
  );
}